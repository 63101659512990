class Storage {
    constructor(opts) {
        const def = {
            type: 'local',
            namespace: 'storage_',
        }
        this.options = Object.assign({}, def, opts);
        this.namespace = this.options.namespace;
        this.storage = window[this.options.type + 'Storage'];
    }
    get(key) {
        let result = this.storage.getItem(this.namespace + key);
        if (result && result.match("^\{(.+:.+,*){1,}\}$")) {
            result = JSON.parse(result);
        }
        return result;
    }
    set(key, val) {
        if (typeof val === 'object') {
            val = JSON.stringify(val);
        }
        return this.storage.setItem(this.namespace + key, val);
    }
    remove(key) {
        return this.storage.removeItem(this.namespace + key);
    }
    clear() {
        return this.storage.clear();
    }
    key(num) {
        return this.storage.key(num);
    }
    getStorage() {
        return {
            ...this.storage
        }
    }
}

export default Storage;
