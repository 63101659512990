import { defineStore } from 'pinia';
import Storage from '@/utils/tools/storage.js';
import { updateAccount } from '~/utils/api/basic_info';
import { useUserStore } from './user';

export const useSkinStore = defineStore('skin', {
    state: () => ({
        skin: '',
        skinConfig: {
            dark: {
                name: '深色',
                color: '#052c5d'
            },
            light: {
                name: '浅色',
                color: '#ffffff'
            }
        },
        currentSkinConfig: {
            name: '',
            color: '',
        }
    }),
    actions: {
        checkSkinName(skin) {
            try {
                const classList = document.querySelector('body').classList;
                if (this.skin) {
                    classList.remove('skin-' + this.skin);
                }
                classList.add('skin-' + skin);
            } catch (error) {}
            this.skin = skin;
            this.currentSkinConfig = this.skinConfig[this.skin];
        },
        getSkin() {
            if (process.client) {
                let storage = new Storage();
                this.checkSkinName(storage.get('skin'));
            }
        },
        async setSkin({ type, save }) {
            if (process.client) {
                const skinType = type || 'dark';
                let storage = new Storage();
                storage.set('skin', skinType);
                if (save) {
                    const userStore = useUserStore();
                    await updateAccount({
                        id: userStore.userinfo.account.id,
                        extend: {
                            skin: skinType
                        }
                    });
                }
                this.checkSkinName(skinType);
            }
        }
    }
})