import { defineStore } from 'pinia';
import { getToken, getUserInfo, processStorage, setToken, setUserInfo } from '@/utils/tools/auth';
import menuConfig from '~~/data/navbarmenu';

export const useUserStore = defineStore('user', {
    state: () => {
        let userinfo = null;
        let token = '';
        if (process.client) {
            userinfo = getUserInfo();
        }
        return {
            platform_logo: '',
            platform_title: '',
            platform_subtitle: '',
            token: token,
            userinfo: userinfo,
            api_info: null,
            menu: null,
        }
    },
    actions: {
        setMenu() {
            this.menu = Object.keys(menuConfig).map(k => {
                if (!menuConfig[k].platform || menuConfig[k].platform.findIndex(p => p == this.api_info?.PLATFORM_API_CATEGORY) > -1) {
                    return {
                        key: k,
                        ...menuConfig[k],
                    };
                }
            }).filter(Boolean);
        },
        setApiInfo(value) {
            this.api_info = value;
        },
        setPlatformLogo(value) {
            this.platform_logo = value;
        },
        setPlatformTitle(value) {
            this.platform_title = value;
        },
        setPlatformSubTitle(value) {
            this.platform_subtitle = value;
        },
        getToken() {
            if (!this.token) {
                this.token = getToken();
            }
            return this.token;
        },
        getUserinfo() {
            if (!this.userinfo) {
                this.userinfo = getUserInfo();
            }
            return this.userinfo;
        },
        setToken(token) {
            setToken(token);
            this.token = token;
        },
        setUserinfo(data) {
            this.userinfo = data;
            if (process.client) {
                setUserInfo(data);
            }
        },
        async resetToken() {
            this.setToken('');
            this.setUserinfo('');
        },
    }
})