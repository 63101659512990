import { defineStore } from 'pinia';
import { useOwnerStore } from './owner';
import { useUserStore } from './user';
import { getToken } from '@/utils/tools/auth';

let count = 0;
const socket_t = {
    rt: null,
    iot: null,
}
const reloadPageHandle = () => {
    window.location.reload();
}
const clearHandle = (type) => {
    socket_t[type] && clearInterval(socket_t[type]);
}

export const useWebsocketStore = defineStore('websocket', {
    state: () => ({
        list: [],
        messageCount: 0,
        errorType: null,
        wsList: {
            rt: '',
            iot: '',
            video: '',
        },
        tableData: null, // 实时数据-表格
        singleData: null, // 实时数据-单条数据
        normalPieData: null, // 实时数据-pie图表1
        reversalPieData: null, // 实时数据-pie图表2
        xyCoordinateMixData: null, // 实时数据-折线图
        eventMapData: null, // 实时数据-事件点位图
        eventData: null, // 全局报警通知
        rtData: null,
        screenLeftData: {},
        screenChartData: [],
    }),
    actions: {
        updateCheck({ key, item, idx }) {
            if (idx == -1) {
                this[key].push(item);
            } else {
                this[key].splice(idx, 1, item);
            }
        },
        init(type) {
            const userStore = useUserStore();
            const ownerStore = useOwnerStore();
            let url = '';
            let onMessage = () => { };
            if (type == 'rt') {
                url = `/iot-${userStore.api_info?.PLATFORM_API_CATEGORY}-service/${userStore.api_info?.PLATFORM_API_CATEGORY}/${userStore.api_info.PLATFORM_API_VERSION}/ws/rt/${userStore.userinfo.owner.id}`;
                onMessage = (data) => {
                    if (typeof data == 'object') {
                        if (data.target == 'chart') {
                            this.screenChartData = data.data;
                        } else {
                            this.screenLeftData[data.target] = data.data;
                        }
                    }
                }
            } else if (type == 'iot') {
                url = `/iot-service/${userStore.api_info?.PLATFORM_API_CATEGORY}/${userStore.api_info.PLATFORM_API_VERSION}/ws/event/${ownerStore.ownerId || userStore.userinfo.owner.id}`;
                onMessage = (data) => {
                    if (typeof data == 'object') {
                        this.eventData = data;
                    }
                }
                // setInterval(() => {
                //     this.eventData = {
                //         timestamp: dayjs().valueOf(),
                //         alarm: [
                //             {
                //                 description: "事件描述1",
                //                 content: "事件内容1" 
                //             },
                //             {
                //                 content: "事件内容2" 
                //             },
                //             {
                //                 description: "事件描述2",
                //             },
                //         ],
                //     }
                // }, 10000);
            }
            this.wsInit({type, url, onMessage});
        },
        wsInit({type, url, onMessage }) {
            console.log(`ws ${type} init`)
            clearHandle(type);
            const userStore = useUserStore();
            if (!userStore.token) return;
            const _this = this;
            let protocol = 'ws';
            let { hostname, origin } = window.location;
            let port = 9527;
            if (hostname.indexOf('localhost') > -1) {
                // protocol = 'wss';
                hostname = '10.8.100.11';
            }
            if (origin.indexOf('https') > -1) {
                protocol = 'wss';
            }
            const ws = new WebSocket(`${userStore.api_info?.PLATFORM_WS_URL}${url}`);
            ws.onopen = function(e) {
                ws.send(JSON.stringify({ "msgType": "Authorization", "parameter": getToken() }));
                socket_t[type] = setInterval(() => {
                    ws.send(JSON.stringify({"msgType":"HEARTBEAT","parameter":{"data":"ping"}}))
                }, 1000 * 45);
            }
            ws.onmessage = async function (e) {
                if (typeof e.data == 'ok') return;
                try {
                    const data = JSON.parse(e.data);
                    onMessage(data);
                } catch (error) {}
            }
            ws.onclose = function (e) {
                console.log(`close websocket ${type}`);
                console.log('close code: ', e.code);
                clearInterval(socket_t[type]);
                if (e.code != 3334) {
                    if (count < 10) {
                        count++;
                        console.log(count);
                        _this.wsInit({ type, url, onMessage });
                    }
                }
            }
            this.wsList[type] = ws;
        },
        manualClose(type) {
            if (this.wsList[type]) {
                clearHandle(type);
                this.wsList[type].close(3334);
                this.wsList[type] = null;
            }
        },
    }
})