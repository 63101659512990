import { defineStore } from 'pinia';
import { processStorage } from '~~/utils/tools/auth';

export const useErrorStore = defineStore('error', {
    state: () => ({
        list: [],
    }),
    actions: {
        getList() {
            if (process.client) {
                this.list = processStorage({key: 'errorlist', type: 'get'}) ? JSON.parse(processStorage({key: 'errorlist', type: 'get'})) : []
            }
        },
        setList(data) {
            if (process.client) {
                let list = this.list;
                const today = dayjs().format('YYYY-MM-DD');
                const idx = list.findIndex(item => dayjs(item.date).format('YYYY-MM-DD') == today);
                if (idx > -1) {
                    if (Array.isArray(list[idx]?.list)) {
                        list[idx].list.unshift({
                            ...data,
                            date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        })
                    } else {
                        list[idx] = {
                            date: today,
                            list: [{
                                ...data,
                                date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                            }]
                        };
                    }
                } else {
                    list.unshift({
                        date: today,
                        list: [{
                            ...data,
                            date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        }]
                    })
                }
                processStorage({key: 'errorlist', type: 'set', value: list});
                this.list = list;
            }
        }
    }
})