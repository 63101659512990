import { default as indexxsluYZoVr8Meta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/analyse/index.vue?macro=true";
import { default as indexzBrFIKO4yjMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/device/index.vue?macro=true";
import { default as indexH6LR0gypVUMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/events/index.vue?macro=true";
import { default as detailxAKN8mEgE9Meta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/history/event/detail.vue?macro=true";
import { default as indexHidIk8AZZBMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/history/event/index.vue?macro=true";
import { default as indexQHFOo7dlxAMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/login/index.vue?macro=true";
import { default as indexGtppTx75bsMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/logout/index.vue?macro=true";
import { default as indexTIi0IKqJLEMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/monitor/index.vue?macro=true";
import { default as indexDIVOzUpjJvMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/overview/index.vue?macro=true";
import { default as indexbCdSHWoBUQMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/screen/index.vue?macro=true";
import { default as indexXDKHzsjBjKMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/statistics/index.vue?macro=true";
import { default as indexLQWKFMt0pDMeta } from "/home/jenkins/.jenkins/workspace/iot-customer-web/pages/video/index.vue?macro=true";
export default [
  {
    name: indexxsluYZoVr8Meta?.name ?? "analyse",
    path: indexxsluYZoVr8Meta?.path ?? "/analyse",
    meta: indexxsluYZoVr8Meta || {},
    alias: indexxsluYZoVr8Meta?.alias || [],
    redirect: indexxsluYZoVr8Meta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/analyse/index.vue").then(m => m.default || m)
  },
  {
    name: indexzBrFIKO4yjMeta?.name ?? "device",
    path: indexzBrFIKO4yjMeta?.path ?? "/device",
    meta: indexzBrFIKO4yjMeta || {},
    alias: indexzBrFIKO4yjMeta?.alias || [],
    redirect: indexzBrFIKO4yjMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/device/index.vue").then(m => m.default || m)
  },
  {
    name: indexH6LR0gypVUMeta?.name ?? "events",
    path: indexH6LR0gypVUMeta?.path ?? "/events",
    meta: indexH6LR0gypVUMeta || {},
    alias: indexH6LR0gypVUMeta?.alias || [],
    redirect: indexH6LR0gypVUMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: detailxAKN8mEgE9Meta?.name ?? "history-event-detail",
    path: detailxAKN8mEgE9Meta?.path ?? "/history/event/detail",
    meta: detailxAKN8mEgE9Meta || {},
    alias: detailxAKN8mEgE9Meta?.alias || [],
    redirect: detailxAKN8mEgE9Meta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/history/event/detail.vue").then(m => m.default || m)
  },
  {
    name: indexHidIk8AZZBMeta?.name ?? "history-event",
    path: indexHidIk8AZZBMeta?.path ?? "/history/event",
    meta: indexHidIk8AZZBMeta || {},
    alias: indexHidIk8AZZBMeta?.alias || [],
    redirect: indexHidIk8AZZBMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/history/event/index.vue").then(m => m.default || m)
  },
  {
    name: indexQHFOo7dlxAMeta?.name ?? "login",
    path: indexQHFOo7dlxAMeta?.path ?? "/login",
    meta: indexQHFOo7dlxAMeta || {},
    alias: indexQHFOo7dlxAMeta?.alias || [],
    redirect: indexQHFOo7dlxAMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexGtppTx75bsMeta?.name ?? "logout",
    path: indexGtppTx75bsMeta?.path ?? "/logout",
    meta: indexGtppTx75bsMeta || {},
    alias: indexGtppTx75bsMeta?.alias || [],
    redirect: indexGtppTx75bsMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: indexTIi0IKqJLEMeta?.name ?? "monitor",
    path: indexTIi0IKqJLEMeta?.path ?? "/monitor",
    meta: indexTIi0IKqJLEMeta || {},
    alias: indexTIi0IKqJLEMeta?.alias || [],
    redirect: indexTIi0IKqJLEMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/monitor/index.vue").then(m => m.default || m)
  },
  {
    name: indexDIVOzUpjJvMeta?.name ?? "overview",
    path: indexDIVOzUpjJvMeta?.path ?? "/overview",
    meta: indexDIVOzUpjJvMeta || {},
    alias: indexDIVOzUpjJvMeta?.alias || [],
    redirect: indexDIVOzUpjJvMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexbCdSHWoBUQMeta?.name ?? "screen",
    path: indexbCdSHWoBUQMeta?.path ?? "/screen",
    meta: indexbCdSHWoBUQMeta || {},
    alias: indexbCdSHWoBUQMeta?.alias || [],
    redirect: indexbCdSHWoBUQMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/screen/index.vue").then(m => m.default || m)
  },
  {
    name: indexXDKHzsjBjKMeta?.name ?? "statistics",
    path: indexXDKHzsjBjKMeta?.path ?? "/statistics",
    meta: indexXDKHzsjBjKMeta || {},
    alias: indexXDKHzsjBjKMeta?.alias || [],
    redirect: indexXDKHzsjBjKMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: indexLQWKFMt0pDMeta?.name ?? "video",
    path: indexLQWKFMt0pDMeta?.path ?? "/video",
    meta: indexLQWKFMt0pDMeta || {},
    alias: indexLQWKFMt0pDMeta?.alias || [],
    redirect: indexLQWKFMt0pDMeta?.redirect,
    component: () => import("/home/jenkins/.jenkins/workspace/iot-customer-web/pages/video/index.vue").then(m => m.default || m)
  }
]