// 驱动信息
export const fetchDriverList = (params) => useRequest.get('/api/common/driver-owns', params);
export const fetchSystemDriverList = params => useRequest.get('/api/common/driver-system', params);
export const fetchSystemMeasuresData = params => useRequest.post('/api/common/driver-measures', params);
export const getCategory = async () => { }
// 单位
export const fetchEnterpriseTree = (data) => useRequest.get('/api/common/enterprise-tree', data);
export const fetchEnterpriseList = (data) => useRequest.get('/api/common/enterprise-list', data);
export const fetchEnterpriseListAll = (data) => useRequest.get('/api/common/enterprise-list-all', data);
// 建筑
export const fetchBuildingList = (data) => useRequest.get('/api/common/building-list', data);
export const fetchBuildingListAll = (data) => useRequest.get('/api/common/building-list-all', data);
export const fetchBuildingTree = (data) => useRequest.get('/api/common/building-tree', data);
export const fetchBuildingInfo = (data) => useRequest.get('/api/common/building-edit', data);
// 楼层
export const fetchFloorList = (data) => useRequest.get('/api/common/floor-list', data);
export const fetchFloorListAll = (data) => useRequest.get('/api/common/floor-list-all', data);
// 点位
export const fetchEquipmentLocate = (params) => useRequest.get('/api/common/equipment-located-list', params);
// 关于Organization的通用接口（tree形式）
export const fetchOrganizationTreeData = params => useRequest.get('/api/common/org-tree-list', params);
// 关于Category的通用接口
export const fetchCategoryData = params => useRequest.get('/api/common/category-list', params);
export const fetchCategoryDataAll = params => useRequest.get('/api/common/category-list-all', params);
// 区域管理
export const fetchResourceRegionData = params => useRequest.get('/api/common/resource-region-list', params);
// 组织架构
export const fetchOrgTree = params => useRequest.get('/api/common/org-tree', params);
// 组织架构用户
export const fetchAccountList = params => useRequest.get('/api/common/account-control', params);
export const updateAccount = params => useRequest.put('/api/common/account-edit', params);
// 修改账户密码
export const updateAccountPassowrd = params => useRequest.put('/api/common/account-password', params)
// 组织架构获取用户无翻页
export const fetchAccountListAll = params => useRequest.get('/api/common/account-list-all', params);
// 电子围栏
export const fetchElectronicFenceData = data => useRequest.get('/api/common/electronic-fence', data);
// 通用dict接口
export const fetchDictionaryList = (data) => useRequest.get('/api/common/dictionary-category-list', data);
export const fetchDictionaryListAll = (data) => useRequest.get('/api/common/dictionary-category-list-all', data);
export const addDictionaryList = (data) => useRequest.post('/api/common/dictionary-category-list', data);
export const updateDictionaryList = (data) => useRequest.put('/api/common/dictionary-category-edit', data);
