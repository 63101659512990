// import fetchFullApi from '../request_middleware';

// export const fetchLoginKey = () => useRequest.get(fetchFullApi('iot-center-service', 'token/key'));
// export const userLoginHandle = params => useRequest.post(fetchFullApi("iot-center-service", 'token/request'), params);
// export const userLogoutHandle = () => useRequest.delete(fetchFullApi('iot-center-service', 'token'));
// export const fetchLoginInfo = () => useRequest.get(fetchFullApi('iot-center-service', 'account/loginInfo'));

export const fetchLoginKey = () => useRequest.get('/api/login/key');
export const userLoginHandle = params => useRequest.post("/api/login/request", params);
export const userLogoutHandle = () => useRequest.delete('/api/logout');
export const fetchLoginInfo = () => useRequest.get('/api/common/login-info');
