<template>
    <svg :class="svgClass" aria-hidden="true">
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<script>

export default {
    name: 'svg-icon',
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String
        }
    },
    setup(props) {
        const { iconClass, className } = toRefs(props);
        const iconName = computed(() => {
            return `#icon-${iconClass.value}`
        });
        const svgClass = computed(() => {
            if (className.value) {
                return 'svg-icon ' + className.value
            } else {
                return 'svg-icon'
            }
        })
        return {
            iconName,
            svgClass
        }
    }
}
</script>
