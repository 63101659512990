import { useUserStore } from '~~/store/user';
import { useErrorStore } from '~~/store/error';


const reLoginCode = [401, 403, 407, 408, 409, 410, 411, 412, 413, 20];

const service = (opts) => {
    const config = {
        method: opts.method,
        params: opts.params,
        body: opts.data,
        onRequest({ options }) {
            const userStore = useUserStore();
            options.headers = new Headers(options.headers);
            if (userStore.getToken()) {
                options.headers.set('Authorization', userStore.token);
            }
        },
        onResponse({ response, options }) {
            const res = response._data;
            const userStore = useUserStore();
            if (res.code !== 0) {
                // if (response.url.indexOf('/geoJSON/') == -1) {
                //     const errorStore = useErrorStore();
                //     errorStore.setList({
                //         ...response,
                //         method: options.method,
                //         data: res,
                //         url: response.url,
                //         headers: response.headers,
                //         statusCode: response.status,
                //         request_token: userStore.token,
                //         body: opts.data,
                //         query: opts.params,
                //     });
                // }
                if (res.msg) {
                    ElMessage({
                        dangerouslyUseHTMLString: true,
                        message: res.msg,
                        type: res.code == 27 ? 'info' : 'error',
                        duration: 5 * 1000
                    });
                }
                if (reLoginCode.findIndex(c => c == res.code) > -1) {
                    if (process.client) {
                        userStore.resetToken().then(() => {
                            window.location.reload();
                        });
                    }
                }
            }
            return res;
        },
    }
    return $fetch(opts.url, config);
}

const headerConfig = {
    json: {
        "Content-Type": 'application/json'
    },
    urlencoded: {
        "Content-Type": 'application/x-www-form-urlencoded'
    },
    multipart: {
        "Content-Type": 'multipart/form-data'
    },
    upload: {
        "Content-Type": 'multipart/form-data1;boundary = ' + new Date().getTime()
    }
}
const commonRequest = (type, params, header) => {
    const obj = {
        method: type,
    }
    if (['get'].findIndex(u => u == type) > -1) {
        obj.params = params;
    } else {
        obj.data = params;
    }
    if (header) {
        obj.headers = Object.assign({}, headerConfig[header])
    }
    return obj;
}
const requestMethods = {
    get: (params) => commonRequest('get', params),
    postFormData: (params) => commonRequest('post', params, 'multipart'),
    post: (params) => commonRequest('post', params, 'json'),
    put: (params) => commonRequest('put', params, 'json'),
    putFormData: (params) => commonRequest('put', params, 'multipart'),
    delete: (params) => commonRequest('delete', params, 'json'),
    // deleteOriginal: (params) => commonRequest('delete', params),
    // postOrignal(data = {}) {
    //     let requestParams = new URLSearchParams();
    //     for (let [k, v] of Object.entries(data)) {
    //         requestParams.append(k, v);
    //     }
    //     return {
    //         method: 'post',
    //         data: requestParams,
    //         headers: Object.assign({}, headerConfig.urlencoded)
    //     }
    // },
}

const _request = (method, url, params, options = {}) => {
    Object.assign(options, {
        url: url
    })
    if (requestMethods[method]) {
        const met = requestMethods[method];
        Object.assign(options, met(params, options));
    } else {
        // 默认get请求
        Object.assign(options, requestMethods.get(params, options));
    }
    return service(options)
}

const methodKeys = Object.keys(requestMethods);

let requestCommon = {};
methodKeys.forEach(m => {
    requestCommon[m] = (url, params, options) => {
        return _request(m, url, params, options)
    }
})

export default requestCommon;
