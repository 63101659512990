export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"/styles/swiper-bundle.min.css"},{"rel":"stylesheet","href":"/styles/video.min.css"},{"rel":"stylesheet","href":"/styles/bigemap.css"},{"rel":"stylesheet","href":"/styles/bigemap.draw.css"}],"style":[],"script":[{"src":"/javascripts/video.min.js"},{"src":"/javascripts/countUp.umd.js"},{"src":"/javascripts/bigemap.min.js"},{"src":"/javascripts/bigemap.draw.min.js"},{"src":"/javascripts/echarts.min.v5.4.1.js"},{"src":"/javascripts/swiper-bundle.min.js"},{"src":"/javascripts/g2.min.js"},{"src":"/javascripts/l7.min.js"},{"src":"/javascripts/l7-draw.min.js"},{"src":"/javascripts/flv.js"},{"src":"/javascripts/countUp.umd.js"},{"src":"https://hm.baidu.com/hm.js?f579bb5da11c02a89d01bd5e4084f3ee"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false