import { defineNuxtPlugin } from '#app';
import config from '~~/config/pageinfo.js';

export default defineNuxtPlugin(nuxtApp => {
    if (nuxtApp._route.name) {
        const route = config[nuxtApp._route.name] || '';
        return {
            provide: {
                'pageInfo': () => route
            }
        }
    }
    return {}
})
