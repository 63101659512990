import { defineStore } from 'pinia';
import { fetchLatestEventsList } from '~~/utils/api/event';
import { useOwnerStore } from './owner';

export const useEventStore = defineStore('event', {
    state: () => {
        return {
            eventList: null,
            tabConfig: [
                {
                    typeName: "全部报警",
                    type: "total",
                },
                {
                    typeName: "系统报警",
                    type: "alarm",
                },
                {
                    typeName: "故障报警",
                    type: "trouble",
                },
                {
                    typeName: "异常报警",
                    type: "abnormal",
                },
                {
                    typeName: "物联监测报警",
                    type: "iot",
                },
                {
                    typeName: "视频报警",
                    type: "video",
                },
                {
                    typeName: "设备启/停反馈",
                    type: "start",
                },
                {
                    typeName: "设备动作反馈",
                    type: "action",
                },
            ],
        }
    },
    actions: {
        update() {
            this.fetchEventList();
        },
        async fetchEventList() {
            const ownerStore = useOwnerStore();
            const res = await fetchLatestEventsList({
                ownerId: ownerStore.ownerId,
            });
            if (res.code == 0) {
                this.eventList = {
                    ...res.data,
                    total: this.tabConfig.reduce((c, n) => {
                        if (res.data[n.type]) {
                            return c.concat(res.data[n.type]);
                        }
                        return c;
                    }, []).sort((a,b) => b.time - a.time),
                }
            }
        }
    }
})