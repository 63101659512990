const config = {
    screen: {
        text: '实时大屏',
        icon: '实时监测',
        path: '/screen',
        platform: ['solar'],
    },
    statistics: {
        text: '数据统计',
        icon: '数据概览',
        path: '/statistics',
        platform: ['solar'],
    },
    monitor: {
        text: '实时监测',
        icon: '实时监测',
        path: '/monitor',
        platform: ['iffs'],
    },
    overview: {
        text: '数据概览',
        icon: '数据概览',
        path: '/overview',
        platform: ['iffs'],
    },
    analyse: {
        text: '分析报告',
        icon: '分析报告',
        path: '/analyse',
        platform: ['iffs'],
    },
    device: {
        text: '设备数据',
        icon: '设备数据',
        path: '/device',
    },
    video: {
        text: '监测视频',
        icon: '监测视频',
        path: '/video',
    },
    history: {
        text: '告警记录',
        icon: '告警记录',
        path: '/history/event',
    },
};

export default config;