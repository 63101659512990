const info = {
    'login': {
        hidden: true,
        title: '登录',
    },
    'logout': {
        hidden: true,
        title: '退出登录'
    },
    'mapbox': {
        hidden: true,
        title: '实时监测'
    },
    'station': {
        hidden: true,
        title: '数据概览'
    },
    'analyse': {
        hidden: true,
        title: '分析数据'
    },
    'history-event': {
        hidden: true,
        title: '告警历史'
    },
    'system': {
        hidden: false,
        title: '系统设置',
    },
    'system-organization': {
        hidden: false,
        title: '组织架构'
    },
    'system-role': {
        hidden: false,
        title: '角色管理'
    },
    'system-module': {
        hidden: false,
        title: '模块管理'
    },
    'system-template': {
        hidden: false,
        title: '模板管理'
    },
    'basic': {
        hidden: false,
        title: '平台数据管理'
    },
    'platform': {
        hidden: false,
        title: '平台管理'
    },
    'module': {
        hidden: false,
        title: '模块管理'
    },
    'data': {
        hidden: false,
        title: '监测数据管理'
    },
    'exhibit': {
        hidden: false,
        title: '模块列表'
    }
}

export default info;