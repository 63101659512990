import { defineNuxtRouteMiddleware } from "#app";
import { useUserStore } from "~~/store/user";
// import { useErrorStore } from "~~/store/error";
import { useWebsocketStore } from "~~/store/socket";
import { useSkinStore } from "~~/store/skin";
import { useEventStore } from "~~/store/event";
import { fetchLoginInfo } from "~/utils/api/login";
import { useOwnerStore } from "~/store/owner";

const whiteList = ['/login', '/no-permission', '/auth-redirect', '/login/map', '/error-record'];
const defaultPath = '/screen';

const fetchUserInfoHandle = async (userStore, skinStore) => {
    const res = await fetchLoginInfo();
    if (res.code == 0) {
        userStore.setUserinfo(res.data.info);
    }
}
const closeLoadingHandle = (loading) => {
    if (!loading) return;
    let t = setTimeout(() => {
        clearTimeout(t);
        loading.close();
    }, 500);
}
export default defineNuxtRouteMiddleware(async function (to, from) {
    const loading = ElLoading.service({ fullscreen: true });
    if (process.client) {
        // const errorStore = useErrorStore();
        const userStore = useUserStore();
        const websocketStore = useWebsocketStore();
        const skinStore = useSkinStore();
        const ownerStore = useOwnerStore();
        const eventStore = useEventStore();
        // errorStore.getList();
        // 获取缓存的用户信息
        userStore.getUserinfo();
        userStore.getToken();
        const hasToken = userStore.token;
        skinStore.getSkin();
        if (hasToken) {
            eventStore.fetchEventList();
            ownerStore.fetchOwnerList();
            fetchUserInfoHandle(userStore, skinStore);
        }
        if (userStore.api_info?.PLATFORM_WS_URL) {
            // 如果有配置websocket url
            Object.keys(websocketStore.wsList).forEach(w => {
                websocketStore.manualClose(w);
            })
            if (hasToken) {
                if (to.path == '/screen') {
                    websocketStore.init('rt');
                }
                websocketStore.init('iot');
            }
        }
        document.title = '跳转中...';
        const scopes = userStore.userinfo?.scopes || [];
        if (hasToken && to.path == '/login') {
            return navigateTo(defaultPath);
        } else if (whiteList.indexOf(to.path) == -1) {
            closeLoadingHandle(loading);
            if (to.path == '/') {
                return navigateTo(defaultPath);
            } if (!Boolean(userStore.token)) {
                return clearError({ redirect: '/login' });
            }
        }
    } else if (process.server) {
        const userStore = useUserStore();
        !userStore.platform_title && userStore.setPlatformTitle(process.env.PLATFORM_TITLE || '智能监测');
        !userStore.planform_subtitle && userStore.setPlatformSubTitle(process.env.PLATFORM_SUBTITLE || '数据平台');
        !userStore.planform_logo && userStore.setPlatformLogo(process.env.PLATFORM_LOGO || '/images/logo.png');
        !userStore.api_info && userStore.setApiInfo({
            BASE_URL: process.env.PLATFORM_BASE_URL || '',
            PLATFORM_API_CATEGORY: process.env.PLATFORM_API_CATEGORY || '',
            PLATFORM_API_VERSION: process.env.PLATFORM_API_VERSION || 'v1',
            PLATFORM_WS_URL: process.env.PLATFORM_WS_URL || '',
        })
        !userStore.menu && userStore.setMenu();
    }
    if (to.path == '/') {
        closeLoadingHandle(loading);
        return navigateTo(defaultPath);
    }
    closeLoadingHandle(loading);
})