
// 单位总树
export const fetchOwnerTree = params => useRequest.get('/api/common/owner-tree', params);
// 单位下级所有单位（电站）（包括自己:self=true）
export const fetchOwnerLeaf = params => useRequest.get('/api/common/owner-leaf', params);
// 单位权限
export const fetchOwnerScopes = params => useRequest.get('/api/common/owner-scopes', params);
// 单位树
export const fetchOwnerScopesById = params => useRequest.get('/api/common/owner-unit-root', params);
// 删除单位
export const deleteOwner = params => useRequest.delete('/api/common/owner-delete', params);
// 查看账号
export const fetchOwnerAccount = params => useRequest.get('/api/common/owner-account', params);
export const updateOwnerScopes = params => useRequest.post('/api/common/owner-root-scopes', params);
// 中国区域树
export const fetchChinaTree = params => useRequest.get('/api/common/geo-100000', params);
// 新增/编辑单位
export const addUnit = params => useRequest.post('/api/common/owner-list', params);
export const updateUnit = params => useRequest.put('/api/common/owner-edit', params);
export const fetchUnitInfo = params => useRequest.get('/api/common/owner-edit', params);