import Cookies from 'js-cookie';
import Storage from './storage';
import { useUserStore } from '~~/store/user';

const AuthConfig = {
    storageHandle: '',
};
//获取对应cookie方法
function nodeCookie(name, strCookie = '') {
    let arrCookie = strCookie.split?.(';');
    let cookie = arrCookie?.find(item => item.split('=')[0] === name);
    return cookie?.split('=')[1] || '';
}

const checkAuthKey = () => {
    if (process.client) {
        const userStore = useUserStore();
        return userStore.userinfo?.owner?.platform || 'iot';
    }
    return 'iot';
}

export function getCookie(key) {
    return Cookies.get(key);
}
export function setCookie(key, value) {
    return Cookies.get(key, value);
}

export function getToken() {
    const TokenKey = checkAuthKey() + '-Token';
    return nodeCookie(TokenKey, useRequestHeaders(['cookie']).cookie) || Cookies.get(TokenKey);
}

export function setToken(token) {
    const TokenKey = checkAuthKey() + '-Token';
    return Cookies.set(TokenKey, token, {
        expires: 7,
    })
}

export function removeToken() {
    const TokenKey = checkAuthKey() + '-Token';
    return Cookies.remove(TokenKey)
}

export function processStorage({ c_key, key, type, value }) {
    const userStore = useUserStore();
    const s_key = c_key ? c_key : (userStore.api_info?.PLATFORM_API_CATEGORY + '-' + key);
    if (!AuthConfig.storageHandle) {
        AuthConfig.storageHandle = new Storage();
    }
    if (type == 'get') {
        return AuthConfig.storageHandle.get(s_key);
    } else if (type == 'set') {
        return AuthConfig.storageHandle.set(s_key, value);
    } else if (type == 'remove') {
        return AuthConfig.storageHandle.remove(s_key);
    }
}
export function getUserInfo() {
    return processStorage({type: 'get', key: 'userinfo'});
}

export function setUserInfo(userInfo) {
    return processStorage({type: 'set', value: userInfo, key: 'userinfo'});
}

export function removeUserInfo() {
    return processStorage({type: 'remove', key: 'userinfo'});
}
