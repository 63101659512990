import { defineNuxtPlugin } from '#app';
import * as ElementIcon from '@element-plus/icons-vue';
// import { ZINDEX_INJECTION_KEY } from 'element-plus'

export default defineNuxtPlugin(nuxtApp => {
    for (const key in ElementIcon) {
        nuxtApp.vueApp.component(`i-${key.toLowerCase()}`, ElementIcon[key]);
        // nuxtApp.vueApp.provide(ZINDEX_INJECTION_KEY, {
        //     current: 0,
        // })
    }
})
