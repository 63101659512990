import { defineStore } from 'pinia';
import { fetchDriverList } from '~/utils/api/basic_info';
import { fetchOwnerLeaf, fetchOwnerTree } from '~/utils/api/owner';
import { useWebsocketStore } from './socket';

export const useOwnerStore = defineStore('owner', {
    state: () => {
        return {
            ownerList: [],
            ownerInfo: {},
            ownerId: '',
            systemList: [],
        }
    },
    actions: {
        async fetchOwnerList() {
            if (this.ownerList.length == 0) {
                const r = await fetchOwnerTree();
                if (r.code == 0) {
                    this.ownerList = Array.isArray(r.data) ? r.data : [r.data];
                    if (!this.ownerId) {
                        this.selectOwner(r.data, 0);
                    }
                }
            }
        },
        selectOwner(info, idx) {
            const wsStore = useWebsocketStore();
            this.ownerInfo = info;
            this.ownerId = info.id;
            wsStore.manualClose('iot');
            wsStore.init('iot');
        },
        async fetchSystemList() {
            const res = await fetchDriverList({ ownerId: this.ownerId });
            if (res.code == 0) {
                this.systemList = res.data.map(item => {
                    return {
                        ...item,
                        name: item.extend?.path_name,
                        driver: item.driverName[0],
                    }
                });
                return this.systemList;
            }
        },
    }
})